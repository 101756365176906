<template>
  <div id="treatP">
    <!-- header -->
    <div class="vx-row w-full h-full">
      <div class="vx-col xl:w-2/5">
        <div class="vx-row">
          <feather-icon
            v-if="EnglishLan"
            @click="back()"
            icon="ChevronLeftIcon"
            style="cursor: pointer"
          >
          </feather-icon>
          <feather-icon
            v-else
            @click="back()"
            icon="ChevronRightIcon"
            style="cursor: pointer"
          >
          </feather-icon>
          <p class="text-xl">
            {{ $t("SurgeryTreatment") }}
          </p>
        </div>
      </div>
      <div class="vx-col xl:w-3/5">
        <h3 class="mb-5 custHeadSurgery">
          {{ $t("BookingSurgeryTitle") }}
        </h3>
      </div>
    </div>
    <!-- body -->
    <div
      class="vs-row w-full p-5"
      style="
        box-sizing: border-box;
        border-style: ridge;
        border: 2px ridge rgb(62, 201, 214) !important;
        border-radius: 9px;
      "
    >
      <div class="vx-row w-full mx-auto">
        <!-- name ofsurgery category -->
        <h1 class="colorHead text-4xl mt-3">
          <p>{{ Model.Surgery.Name }}</p>
        </h1>
      </div>
      <div class="vx-row w-full">
        <div class="vx-col lg:w-2/3 w-full mt-5">
          <imageLazy
            :imageStyle="{
              'border-radius': '15px',
              height: '200px',
              width: '14vw',
              'object-fit': 'cover',
            }"
            :imageClass="'doctorImage'"
            :src="baseURL + Model.Hospital.ImagePath"
            placeHolderType="hospital"
          />

          <h6 class="HospitalTitle mt-4 mb-4">
            {{
              $vs.rtl
                ? $t("Hosptial") + " " + Model.Hospital.Name
                : Model.Hospital.Name + " " + $t("Hosptial")
            }}
          </h6>
          <h6 class="HospitalInfo lg:w-2/3 w-full">
            <p v-html="Model.Hospital.HospitalInfo"></p>
          </h6>
          <!-- hospital info section-->
          <div class="vx-col w-full mt-5 ml-1">
            <div class="vx-col">
              <star-rating
                class="mt-3"
                :max-rating="5"
                :star-size="30"
                :read-only="true"
                v-model="Model.Hospital.Rate"
              ></star-rating>

              <div
                class="mt-4"
                v-if="!$acl.check('patient') && !$acl.check('notAuthenticated')"
              >
                <h6>
                  {{ Model.Hospital.Email }}
                </h6>
                <h6 class="mt-1">
                  {{ Model.Hospital.Phone }}
                </h6>
              </div>
              <!-- <u class="text-primary text-xl">{{ $t("HospitalInfo") }} </u> -->
            </div>
          </div>
        </div>
        <div class="vx-col lg:w-1/3 w-full mt-5">
          <vs-button
            v-if="!IsDoctorRole"
            class="w-full mt-2 customizer-btn text-sm bg-white text-primary"
          >
            <p
              :style="[
                Model.PriceAfterDiscount
                  ? { 'text-decoration': 'line-through' }
                  : {},
              ]"
            ></p>
            <p
              class="sizeCust"
              :style="[
                Model.PriceAfterDiscount &&
                Model.PriceAfterDiscount != Model.DefaultHospitalPriceInUSD
                  ? { 'text-decoration': 'line-through' }
                  : {},
              ]"
            >
              <span style="font-weight: bold">
                {{ $t("PriceStartingFrom") }}
                <span v-if="Model.DefaultHospitalPriceInUSD > 0">{{
                  Model.DefaultHospitalPriceInUSD.toFixed(2).replace(
                    /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                    "$1,"
                  )
                }}</span>
                {{ $t("$") }}
              </span>
            </p>
          </vs-button>

          <vs-button
            v-if="
              Model.PriceAfterDiscount &&
              Model.PriceAfterDiscount != Model.DefaultHospitalPriceInUSD &&
              !IsDoctorRole
            "
            class="w-full mt-2 customizer-btn text-sm bg-white text-primary"
            style="font-weight: bold"
            >{{ $t("PriceStartingFrom") }}
            <span v-if="Model.PriceAfterDiscount > 0">{{
              Model.PriceAfterDiscount.toFixed(2).replace(
                /(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                "$1,"
              )
            }}</span>
            {{ $t("$") }}
          </vs-button>

          <vs-button
            v-if="showAddRequest && Model.StatusID != 3 && Model.StatusID != 5"
            class="w-full mt-2 customizer-btn text-sm text-white bg-primary"
            @click="ShowAddSurgeryItemPopup = true"
            >{{ $t("RequiredDocs") }}
          </vs-button>

          <vs-button
            v-if="showAddRequest && Model.StatusID != 3 && Model.StatusID != 5"
            class="w-full mt-2 customizer-btn text-sm text-white bg-primary"
            @click="showDownloadReports()"
            >{{ $t("PatientMedicalReportAndRequesteds") }}
          </vs-button>

          <vs-button
            v-if="
              !$acl.check('hospital') &&
              !$acl.check('hospitalgroup') &&
              !IsDoctorRole
            "
            @click="BookTheSurgery()"
            class="sizeCust w-full mt-2 customizer-btn text-sm text-white bg-primary"
            >{{ $t("BookTheSurgery") }}
          </vs-button>

          <vs-button
            v-if="
              $acl.check('hospital') &&
              $route.params.ViewTypeID &&
              $route.params.ViewTypeID == 1
            "
            @click="EditHospitalSurgery(Model.ID)"
            class="w-full mt-2 customizer-btn text-sm text-white bg-primary"
            >{{ $t("EditTreatment") }}
          </vs-button>

          <p
            class="p-2 text-sm"
            v-if="
              !$acl.check('hospital') &&
              !$acl.check('hospitalgroup') &&
              !IsDoctorRole
            "
          >
            {{ $t("selectSpacificDoctor") }}
            <a
              :href="
                'mailto:patient.service@doclinia.com?subject=book for surgery ' +
                Model.Surgery.Name
              "
              >patient.service@doclinia.com</a
            >
          </p>
        </div>
      </div>
      <!-- first section -->

      <div class="vx-row mb-4 mt-5">
        <div
          class="vx-col lg:w-1/3 w-full"
          v-if="
            Model.HospitalSurgeryIncludedItems &&
            Model.HospitalSurgeryIncludedItems.length > 0
          "
        >
          <!-- included section -->
          <div>
            <h3 class="greenColor">
              {{ $t("SurgeryTreatmentIncludes") }}
            </h3>
          </div>
          <div
            class="vx-row mt-2 ml-2 mr-2"
            v-for="item in Model.HospitalSurgeryIncludedItems"
            :key="item.ID"
          >
            <p class="circle primaryBackColor mt-2 ml-2 mr-2"></p>
            <h5 style="width: 90%" v-if="item.SurgeryItem">
              <p v-if="item.SurgeryItem.Name !=null">{{ item.SurgeryItem.Name }}</p>
              <!-- <p v-if="item.SurgeryItem.Name == null && item.SurgeryItem.NameEn !=null">{{ item.SurgeryItem.NameEn }}</p> -->
            </h5>
          </div>
        </div>
        <div class="vx-col lg:w-1/3 w-full"></div>
        <div
          class="vx-col lg:w-1/3 w-full"
          v-if="
            Model.HospitalSurgeryExcludedItems &&
            Model.HospitalSurgeryExcludedItems.length > 0
          "
        >
          <!-- excluded section -->
          <div>
            <h3>
              <p class="greenColor">
                {{ $t("SurgeryTreatmentNotIncludes") }}
              </p>
            </h3>
          </div>
          <div
            class="vx-row mt-2 ml-2 mr-2"
            v-for="item in Model.HospitalSurgeryExcludedItems"
            :key="item.ID"
          >
            <p class="circle primaryBackColor mt-2 ml-2 mr-2"></p>
            <h5 style="width: 90%" v-if="item.SurgeryItem">
              <p v-if="item.SurgeryItem.Name !=null">{{ item.SurgeryItem.Name }}</p>
              <!-- <p v-if="item.SurgeryItem.NameEn !=null &&item.SurgeryItem.Name ==null">{{item.SurgeryItem.NameEn}}</p> -->
            </h5>
          </div>
        </div>
      </div>

      <!-- ----------Doctors----------- -->
      <div
        class="mt-3"
        v-if="
          Model.HospitalSurgeryDoctors &&
          Model.HospitalSurgeryDoctors.length > 0
        "
      >
        <h3>
          <p class="greenColor">{{ $t("DoctorSameSpecialty") }}:</p>
        </h3>
      </div>

      <div id="doctors" class="vx-row w-full mt-1">
        <div
          class="vx-col lg:w-1/3 w-full mt-2"
          v-for="item in Model.HospitalSurgeryDoctors"
          :key="item.ID"
        >
          <vx-card class="cutomCard">
            <div class="vx-row docCardRow">
              <div class="vx-col lg:w-2/5 docCardCol">
                <imageLazy
                  :imageStyle="{
                    'border-radius': '5px',
                    width: '100%',
                    height: '120px',
                  }"
                  :imageClass="'doctorImage'"
                  :src="baseURL + item.Doctor.ProfileImagePath"
                  placeHolderType="doctor"
                />
              </div>
              <div class="vx-col h-full lg:w-3/5">
                <h5 class="mt-2" style="font-weight: bold">
                  <p>{{ $t("DrTitle") + " " + item.Doctor.Name }}</p>
                </h5>
                <h6
                  class="mt-2"
                  v-if="
                    !$acl.check('patient') && !$acl.check('notAuthenticated')
                  "
                >
                  <p class="text-sm">{{ item.Doctor.Email }}</p>
                </h6>
                <h6 class="mt-1">
                  <p class="text-sm" v-if="item.Doctor.Bio != null">
                    {{ item.Doctor.Bio.substring(0, 100) }}...
                  </p>
                  <p class="text-sm" v-else>
                    {{ $t("Biography") }}
                  </p>
                </h6>
                <div class="mt-1">
                  <a
                    style="border-radius: 3px"
                    class="p-1 text-sm text-white bg-primary"
                    @click="
                      $router.push(
                        '/DoctorProfile/' + item.Doctor.ID + '/' + true
                      )
                    "
                  >
                    {{ $t("profileLink") }}
                  </a>
                </div>
                <div class="mt-3 mb-3" v-if="item.Doctor.CVPath">
                  <a
                    style="border-radius: 3px"
                    class="p-1 text-sm text-white bg-primary"
                    :href="baseURL + item.Doctor.CVPath"
                    target="_blank"
                  >
                    C.V.
                  </a>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>

      <div
        class="vx-row lg:w-2/3 w-full mt-2"
        v-if="
          !$acl.check('hospital') &&
          !$acl.check('hospitalgroup') &&
          !IsDoctorRole
        "
      >
        <h3 class="mr-4 ml-4 greenColor">{{ $t("Paymentconditions") }} :</h3>
        <p class="w-full" style="color: #004477"></p>
        <h5 class="mr-8 ml-8 lg:w-2/3" style="color: #004477">
          <label style="font-size: 1.5rem">.</label>
          {{ $t("PaymentconditionDescriptionFirst") }}<br />
          <label style="font-size: 1.5rem">.</label>
          {{ $t("PaymentconditionDescriptionSecound") }}<br />
          <label style="font-size: 1.5rem">.</label>
          <!-- {{ $t("PaymentconditionDescriptionThird") }} -->
        </h5>
      </div>
      <div
        class="vx-row lg:w-2/3 w-full mt-10"
        v-if="
          !$acl.check('hospital') &&
          !$acl.check('hospitalgroup') &&
          !IsDoctorRole
        "
      >
        <h3 class="mr-4 ml-4 greenColor">
          {{ $t("Cancellationconditions") }}:
        </h3>
        <p
          class="w-full"
          style="color: #004477"
          v-if="
            !$acl.check('hospital') &&
            !$acl.check('hospitalgroup') &&
            !IsDoctorRole
          "
        ></p>
        <h5 class="mr-8 ml-8 lg:w-2/3" style="color: #004477">
          <label style="font-size: 1.5rem">.</label>

          {{ $t("CancellationconditionDescriptionFirst") }}<br />
          <label style="font-size: 1.5rem">.</label>

          {{ $t("CancellationconditionDescriptionSecond") }}<br />
          <label style="font-size: 1.5rem">.</label>

          {{ $t("CancellationconditionDescriptionThird") }}<br />
          <label style="font-size: 1.5rem">.</label>

          {{ $t("CancellationconditionDescriptionFourth") }}<br />
          <!-- <a
            :href="
              'mailto:Patient.Service@Doctor4me.Org?subject=book for surgery ' +
              Model.Surgery.Name
            "
            >Patient.Service@Doctor4me.Org</a
          > -->
          <!-- {{ $t("CancellationconditionDescriptionRemain") }} -->
        </h5>

        <!-- </p> -->
      </div>
    </div>

    <!-- popup  1 from 5 -->
    <vs-popup
      class="popcard"
      fullscreen
      title=""
      :active.sync="ShowBookTheSurgery1"
    >
      <div
        class="mt-10 flex w-full bg-img vx-row no-gutter items-center justify-center"
        style="width: 100%"
        id="page-login"
      >
        <div
          class="vx-col sm:w-1/4 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4 mycard"
        >
          <vx-card class="w-full">
            <div class="absolute flex inset-x-0 top-0 mt-5 justify-center">
              <popLogo />
            </div>

            <div slot="no-body" class="full-page-bg-color CustBG">
              <div class="vx-row">
                <!-- left titles -->
                <div
                  class="pl-10 pr-10 vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
                >
                  <p
                    style="
                      padding: 0px;
                      font: normal normal 50 20px/30px UbuntuRegular;
                      color: #ffffff;
                      opacity: 1;
                    "
                  >
                    {{ $t("BookingYourSurgery") }}
                    <br />
                  </p>
                  <div class="">
                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/appointment.png"
                          style="max-width: 50px"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="CurrentPageStatment pt-3">
                          {{ $t("STEPONE") }}<br />{{ $t("OneSetSurgery") }}
                        </p>
                      </div>
                    </div>

                    <hr class="line" />

                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/document.png"
                          style="max-width: 40px; opacity: 0.3"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="OtherPageStatment pt-4">
                          {{ $t("STEPTWO") }}<br />{{ $t("TwoSetSurgery") }}
                        </p>
                      </div>
                    </div>
                    <hr class="line" style="opacity: 0.3" />

                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/payment-method.png"
                          style="max-width: 50px; opacity: 0.3"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="OtherPageStatment pt-4">
                          {{ $t("STEPTREE") }}<br />{{ $t("ThreeSetSurgery") }}
                        </p>
                      </div>
                    </div>

                    <div class="OtherPageStatment">
                      <span
                        style="
                          color: white;
                          font-size: 15px;
                          text-align: center;
                        "
                        >{{ $t("AcceptPaymentText") }}
                      </span>
                      <div
                        class="vx-row pt-3 pb-3"
                        style="text-align: center; justify-content: center"
                        fixed
                      >
                        <img
                          src="@/assets/images/Group 2.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />

                        <img
                          src="@/assets/images/Group 1.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                        <img
                          src="@/assets/images/Group 3.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                        <img
                          src="@/assets/images/Group 4.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- right titles -->
                <div
                  class="vx-col sm:w-1/2 md:w-full lg:w-1/2 d-theme-dark-bg"
                  style="border-radius: 31px 31px 31px 31px"
                >
                  <div class="pt-5">
                    <!-- date -->
                    <div class="vx-row text-center pl-12 pr-12">
                      <div class="mb-4">
                        <h6
                          class="bg-white mt-8 text-black"
                          style="margin-left: 10px"
                        >
                          {{ $t("From") }}:
                          <span
                            style="color: #aba9a4"
                            v-if="Model.DateFromFormated"
                          >
                            {{ Model.DateFromFormated }}</span
                          >
                          <span style="color: #aba9a4" v-else>
                            {{ $t("ChoiseDate") }}</span
                          >
                          {{ $t("To") }}:
                          <span
                            style="color: #aba9a4"
                            v-if="Model.DateToFormated"
                          >
                            {{ Model.DateToFormated }}</span
                          >
                          <span style="color: #aba9a4" v-else>
                            {{ $t("ChoiseDate") }}</span
                          >
                        </h6>
                      </div>
                    </div>
                    <!-- date picker -->
                    <div class="pl-12 pr-12 mt-5">
                      <v-date-picker
                        v-model="Model.range"
                        multiple="true"
                        is-range
                        :locale="$vs.rtl ? 'ar' : 'en'"
                        @change="changeDateFormat"
                        :min-date="addDays(4)"
                        @dayclick="changeDateFormat"
                      />
                    </div>

                    <div>
                      <label class="w-full pl-12 pr-12 mt-3"
                        >{{ $t("MedicalDocumentsNeeded") }} :</label
                      >

                      <div
                        class="vx-row mt-5 m-5"
                        v-for="doc in Model.HospitalSurgeryRequiredDocuments"
                        :key="doc.ID"
                      >
                        <div class="vx-col lg:w-3/4 w-full vx-row">
                          <p class="circle primaryBackColor mt-4"></p>

                          <h6 class="ml-1 mr-1 mt-2">
                            <p style="font-weight: bold">
                              {{ doc.DocumentTypeName }}-{{ doc.Description }}
                            </p>
                          </h6>
                        </div>

                        <div class="vx-col lg:w-1/4 w-full vx-row">
                          <input
                            type="file"
                            class="hidden"
                            :id="'uploadImgInput' + doc.ID"
                            :ref="'uploadImgInput' + doc.ID"
                            accept="image/*"
                            @change="AttachmentUploaded"
                            @on-success="successUpload"
                          />

                          <feather-icon
                            @click="upload(doc.ID, doc)"
                            icon="UploadIcon"
                            style="width: 20px"
                          >
                          </feather-icon>

                          <vs-checkbox
                            disabled
                            v-model="doc.IsUploaded"
                          ></vs-checkbox>
                          <feather-icon
                            v-if="doc.IsUploaded"
                            icon="TrashIcon"
                            @click.stop="RemoveDocuments(doc)"
                            class="cursor-pointer mt-2"
                          ></feather-icon>
                        </div>
                      </div>
                    </div>

                    <div
                      class="flex flex-wrap justify-center flex-col-reverse sm:flex-row"
                    >
                      <vs-button
                        class="w-full sm:w-auto text-center mt-2"
                        color="primary"
                        :disabled="!canProceed"
                        @click="Proceed"
                        >{{ $t("Proceed") }}</vs-button
                      >
                    </div>
                    <div class="text-center mb-5">
                      <vs-button
                        type="line"
                        color="black"
                        @click="BackPopup1()"
                        class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                        >{{ $t("Back") }}</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <!-- popup  2 from 5 -->
    <!-- payment popup -->
    <vs-popup
      class="popcard"
      fullscreen
      title=""
      :active.sync="ShowConfirmation"
    >
      <div
        class="mt-10 flex w-full bg-img vx-row no-gutter items-center justify-center"
        style="width: 100%"
        id="page-login"
      >
        <div
          class="vx-col sm:w-1/4 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4 mycard"
        >
          <vx-card>
            <div class="absolute flex inset-x-0 top-0 mt-5 justify-center">
              <popLogo />
            </div>

            <div slot="no-body" class="full-page-bg-color CustBG">
              <div class="vx-row">
                <div
                  class="pl-10 pr-10 vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
                >
                  <p
                    style="
                      padding: 0px;
                      font: normal normal 50 20px/30px UbuntuRegular;
                      color: #ffffff;
                      opacity: 1;
                    "
                  >
                    {{ $t("TwoSetSurgery") }}
                    <br />
                  </p>
                  <div class="">
                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/appointment.png"
                          style="max-width: 50px; opacity: 0.3"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="OtherPageStatment pt-3">
                          {{ $t("STEPONE") }}<br />{{ $t("OneSetSurgery") }}
                        </p>
                      </div>
                    </div>

                    <hr class="line" />

                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/document.png"
                          style="max-width: 40px"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="CurrentPageStatment pt-4">
                          {{ $t("STEPTWO") }}<br />{{ $t("TwoSetSurgery") }}
                        </p>
                      </div>
                    </div>
                    <hr class="line" style="opacity: 0.3" />

                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/payment-method.png"
                          style="max-width: 50px; opacity: 0.3"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="OtherPageStatment pt-4">
                          {{ $t("STEPTREE") }}<br />{{ $t("ThreeSetSurgery") }}
                        </p>
                      </div>
                    </div>
                    <div class="OtherPageStatment">
                      <span
                        style="
                          color: white;
                          font-size: 15px;
                          text-align: center;
                        "
                        >{{ $t("AcceptPaymentText") }}
                      </span>
                      <div
                        class="vx-row pt-3 pb-3"
                        style="text-align: center; justify-content: center"
                      >
                        <img
                          src="@/assets/images/Group 2.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />

                        <img
                          src="@/assets/images/Group 1.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                        <img
                          src="@/assets/images/Group 3.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                        <img
                          src="@/assets/images/Group 4.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="vx-col sm:w-1/2 md:w-full lg:w-1/2 d-theme-dark-bg"
                  style="border-radius: 31px 31px 31px 31px"
                >
                  <div class="pl-12 pr-12 pt-5">
                    <div class="mt-10">
                      <p
                        style="
                          text-align: initial;
                          font: normal normal 200 20px/21px UbuntuRegular;
                          letter-spacing: 0px;
                          color: #000000;
                        "
                      >
                        {{ $t("medicalReportsHospitalHeading") }}
                      </p>
                    </div>

                    <div class="m-10">
                      <p
                        v-if="Model.ProcessingFees"
                        style="
                          text-align: center;
                          font: normal normal 300 20px/21px UbuntuRegular;
                          letter-spacing: 0px;
                          color: #000000;
                        "
                      >
                        {{ $t("deposit") }}:
                        {{
                          (Model.ProcessingFees + Model.VAT)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                        }}{{ "$" }}
                      </p>
                    </div>
                    <div class="mt-10">
                      <p
                        v-if="Model.DefaultHospitalPriceInUSD"
                        style="
                          text-align: center;
                          font: normal normal 200 20px/21px UbuntuRegular;
                          letter-spacing: 0px;
                          color: #000000;
                        "
                      >
                        {{ $t("RemainingAmountPaid") }}:
                        {{
                          (
                            Model.DefaultHospitalPriceInUSD -
                            (Model.ProcessingFees + Model.VAT)
                          )
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                        }}{{ "$" }}
                      </p>
                    </div>
                    <div
                      class="flex flex-wrap justify-center flex-col-reverse sm:flex-row mb-5 mt-5"
                    >
                      <vs-button
                        class="w-full sm:w-auto text-center"
                        color="primary"
                        type="filled"
                        @click="PayNow"
                        >{{ $t("Confirm") }}</vs-button
                      >
                    </div>
                    <div class="text-center mb-5">
                      <vs-button
                        type="line"
                        color="black"
                        @click="BackProcessFeesPopup()"
                        class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                        >{{ $t("Close") }}</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <!-- popup 3 from 5  payment -->
    <vs-popup class="popcard" fullscreen title="" :active.sync="Showpayment">
      <div class="flex w-full bg-img fontMedium">
        <div
          class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center mycard items-center justify-center"
        >
          <vx-card>
            <div
              class="absolute flex inset-x-0 top-0 mt-5 justify-center"
              slot="no-body"
            >
              <img src="@/assets/images/logo.png" style="width: 100px" alt="" />
            </div>
            <div slot="no-body" class="full-page-bg-color CustBG">
              <div class="vx-row">
                <div
                  class="pl-10 pr-10 vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
                >
                  <p
                    style="
                      padding: 0px;
                      font: normal normal 50 20px/30px UbuntuRegular;
                      color: #ffffff;
                      opacity: 1;
                    "
                  >
                    {{ $t("ThreeSetSurgery") }}
                    <br />
                  </p>
                  <div class="">
                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/appointment.png"
                          style="max-width: 50px; opacity: 0.3"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="OtherPageStatment pt-3">
                          {{ $t("STEPONE") }}<br />{{ $t("OneSetSurgery") }}
                        </p>
                      </div>
                    </div>

                    <hr class="line" />

                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/document.png"
                          style="max-width: 40px; opacity: 0.3"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="OtherPageStatment pt-4">
                          {{ $t("STEPTWO") }}<br />{{ $t("TwoSetSurgery") }}
                        </p>
                      </div>
                    </div>
                    <hr class="line" style="opacity: 0.3" />

                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/payment-method.png"
                          style="max-width: 50px"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="CurrentPageStatment pt-4">
                          {{ $t("STEPTREE") }}<br />{{ $t("ThreeSetSurgery") }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <span
                        style="
                          color: white;
                          font-size: 15px;
                          text-align: center;
                        "
                        >{{ $t("AcceptPaymentText") }}
                      </span>
                      <div
                        class="vx-row pt-3 pb-3"
                        style="text-align: center; justify-content: center"
                      >
                        <img
                          src="@/assets/images/Group 2.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />

                        <img
                          src="@/assets/images/Group 1.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                        <img
                          src="@/assets/images/Group 3.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                        <img
                          src="@/assets/images/Group 4.png"
                          style="max-width: 60px"
                          alt=""
                          class="ml-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
                  style="border-radius: 31px 31px 31px 31px"
                >
                  <div class="p-8" style="margin-top: 60px">
                    <div class="vx-card__title mb-4"></div>
                    <div
                      class="vx-col sm:w-full md:w-full mx-auto self-center d-theme-dark-bg"
                      style="height: 165px"
                    >
                      <p
                        v-if="$route.params.ViewTypeID == 14"
                        style="color: green"
                      >
                        {{ $t("PaymentSuccess") }}
                      </p>
                      <p
                        v-if="$route.params.ViewTypeID != 14"
                        style="color: red"
                      >
                        {{ $t("Paymentfail") }}
                      </p>
                    </div>

                    <div class="text-center">
                      <vs-button
                        type="line"
                        color="black"
                        @click="PayNow"
                        v-if="$route.params.ViewTypeID != 14"
                        class="w-full m-12 sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                        >{{ $t("Pay") }}</vs-button
                      >
                      <vs-button
                        type="line"
                        color="black"
                        v-if="$route.params.ViewTypeID == 14"
                        @click="Showpayment = false"
                        class="w-full m-12 sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                        >{{ $t("Back") }}</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <!-- popup 4 from 5 BookingSurgeryConfirm-->
    <vs-popup class="popcard" title="" :active.sync="ShowBookingSurgeryConfirm">
      <div class="vx-col mycard">
        <vx-card>
          <div slot="no-body">
            <div
              class="popBackGround vx-row no-gutter justify-center items-center"
            >
              <div class="vs-row">
                <div class="logo mt-5">
                  <img
                    width="100"
                    height="100"
                    class="TextLogo"
                    alt=""
                    src="@/assets/images/logo.png"
                  />
                  <!-- <h3 class="TextLogo">
                      Doctor <span style="color: #0d66d0">4</span> Me
                    </h3> -->
                </div>
                <div>
                  <p class="P-Text mt-8">
                    Your medical reports are sent to the hospital
                  </p>
                  <p class="P-Text">
                    The corresponding department will send you a message with:
                  </p>
                  <ul class="mt-5">
                    <li>The confirmed date</li>
                    <li>Additional requested documents</li>
                    <li>Online appointment if needed</li>
                    <li>Payment date and bank account</li>
                  </ul>
                </div>
                <div class="mt-12">
                  <vs-button
                    style="width: 12%"
                    @click="ShowBookingSurgeryConfirm = false"
                  >
                    OK
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </vs-popup>

    <!-- popup 5 from 5  payment -->
    <vs-popup
      class="popcard"
      fullscreen
      title=""
      :active.sync="ShowDocumentUpload"
    >
      <div class="flex w-full bg-img fontMedium">
        <div
          class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center mycard items-center justify-center"
        >
          <vx-card>
            <div
              class="absolute flex inset-x-0 top-0 mt-5 justify-center"
              slot="no-body"
            >
              <img src="@/assets/images/logo.png" style="width: 100px" alt="" />
            </div>
            <div slot="no-body" class="popBackGround full-page-bg-color">
              <div class="vx-row">
                <div
                  class="vx-col hidden sm:hidden md:hidden text-center lg:block lg:w-1/2 mx-auto self-center"
                >
                  <span
                    style="
                      text-align: center;
                      font: normal normal 100 30px/51px Futura PT;
                      letter-spacing: 0px;
                      color: #ffffff;
                      opacity: 1;
                    "
                  >
                    {{ $t("ChooseDocumentType") }}<P> </P>
                  </span>
                </div>
                <div
                  class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
                  style="border-radius: 31px 31px 31px 31px"
                >
                  <div class="p-8" style="margin-top: 60px">
                    <div class="vx-card__title mb-4"></div>
                    <div
                      class="vx-col sm:w-full md:w-full mx-auto self-center d-theme-dark-bg"
                    >
                      <div class="vx-row text-center">
                        <v-select
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          class="w-full ml-20 mr-20 mb-10"
                          label="Name"
                          v-model="documentModel.DocumentTypeID"
                          :options="DocumentTypes"
                          :reduce="(ID) => ID.ID"
                          placeholder="DocumentType"
                        />
                      </div>
                      <div class="vx-row text-center">
                        <v-select
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          v-if="documentModel.DocumentTypeID == 1"
                          class="w-full ml-20 mr-20 mb-10"
                          label="Name"
                          v-model="documentModel.ScanTypeID"
                          :options="ScanTypes"
                          :reduce="(ID) => ID.ID"
                          placeholder="ScanType"
                        />
                      </div>
                      <div class="vx-row text-center">
                        <v-select
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          v-if="documentModel.DocumentTypeID == 2"
                          class="w-full ml-20 mr-20 mb-10"
                          label="Name"
                          v-model="documentModel.AnalysisTypeID"
                          :options="AnalysisTypes"
                          :reduce="(ID) => ID.ID"
                          placeholder="AnalysisType"
                        />
                      </div>
                      <div class="vx-row text-center">
                        <v-select
                          :dir="$vs.rtl ? 'rtl' : 'ltr'"
                          v-if="documentModel.DocumentTypeID == 3"
                          class="w-full ml-20 mr-20 mb-10"
                          label="Name"
                          v-model="documentModel.ReportTypeID"
                          :options="ReportTypes"
                          :reduce="(ID) => ID.ID"
                          placeholder="ReportType"
                        />
                      </div>
                    </div>

                    <div
                      class="vx-col sm:w-full md:w-full mx-auto self-center d-theme-dark-bg"
                    >
                      <div class="vx-row text-center">
                        <vs-upload
                          class="w-full ml-20 mr-20 mb-10"
                          automatic
                          :limit="1"
                          :action="baseURL + 'api/Common/UploadFile'"
                          :headers="UploadRequestHeader"
                          @on-success="successUpload"
                        />
                      </div>
                      <div class="vx-row text-center">
                        <vs-button
                          :disabled="
                            !this.Uploaded || !documentModel.DocumentTypeID
                          "
                          class="w-full ml-20 mr-20 mb-10"
                          color="primary"
                          @click="AddAttachmnet"
                          >{{ $t("Procced") }}</vs-button
                        >
                      </div>
                    </div>
                    <div class="text-center">
                      <vs-button
                        type="line"
                        color="black"
                        @click="
                          ShowDocumentUpload = false;
                          ShowBookTheSurgery1 = true;
                        "
                        class="w-full m-12 sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                        >{{ $t("Back") }}</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <!-- popup 6 from 6  LoginPopup -->
    <vs-popup class="popcard" title="" :active.sync="ShowLoginPopup">
      <UserNotLogin
        :MessageAction="'BookTheSurgery'"
        v-if="ShowLoginPopup"
      ></UserNotLogin>
    </vs-popup>

    <!-- popup 7 from 7 RequiredDocPopup -->

    <vs-popup title="" :active.sync="ShowAddSurgeryItemPopup">
      <AddSurgeryItemPopup
        :itemModel="addItemModel"
        v-if="ShowAddSurgeryItemPopup"
        @closePop="ShowAddSurgeryItemPopup = false"
        @AddItem="AddPatientMedicalReportRequest()"
      ></AddSurgeryItemPopup>
    </vs-popup>

    <vs-popup class="w-full" title="" :active.sync="showDownloadReportsfield">
      <showDownloadReportsTreatmentsCom
        :patientDoc="patientDoc"
        v-if="showDownloadReportsfield"
        @closePop="showDownloadReportsfield = false"
      ></showDownloadReportsTreatmentsCom>
    </vs-popup>
  </div>
</template>

<script>
import showDownloadReportsTreatmentsCom from "@/views/Patient/PatientSurgeries&Treatments/PatientMedicalReportPopup.vue";
import imageLazy from "@/components/image_lazy.vue";
import point from "@/components/point.vue";
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import StarRating from "vue-star-rating";

import moment from "moment";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import UserNotLogin from "@/views/UserNotLogin.vue";
import AddSurgeryItemPopup from "@/views/Patient/PatientSurgeries&Treatments/AddSurgeryDocumentPopup.vue";
import axios from "@/axios.js";
export default {
  data() {
    return {
      Item: {},
      EnglishLan: false,
      ShowAddSurgeryItemPopup: false,
      Showpayment: false,
      showDownloadReportsfield: false,
      showAddRequest: false,
      ShowLoginPopup: false,
      ShowBookTheSurgery1: false,
      ShowBookingSurgeryConfirm: false,
      ShowDocumentUpload: false,
      baseURL: domain,
      IsDoctorRole: false,
      uploadUrl: domain + "api/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      Model: {
        Hospital: {},
        Surgery: {},
        range: {},
        HospitalSurgeryDoctors: [],
        HospitalSurgeryIncludedItems: [],
        HospitalSurgeryExcludedItems: [],
        HospitalSurgeryRequiredDocuments: [],
      },
      patientDoc: {},
      addItemModel: {},
      ListOfDocument: [],
      PatientMedicalHistoryScans: [],
      PatientMedicalHistoryRepors: [],
      PatientMedicalHistoryAnalysis: [],
      ShowConfirmation: false,
      passwordType: "password",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      email: this.$route.params.email,
      password: "",
      code: null,
      password2: null,
      documentModel: {},
      patientModel: {},
      Uploaded: false,
      value1: "",
      surgeryModel: {},
    };
  },

  components: {
    AddSurgeryItemPopup,
    showDownloadReportsTreatmentsCom,
    StarRating,
    UserNotLogin,
    imageLazy,
    // BookingSurgeryConfirm,

    point,
  },
  computed: {
    canProceed() {
      debugger;
      return this.Model.range;
    },
    // &&
    //     ((this.Model.HospitalSurgeryRequiredDocuments != null &&
    //       this.Model.HospitalSurgeryRequiredDocuments != undefined &&
    //       this.Model.HospitalSurgeryRequiredDocuments.length > 0 &&
    //       this.Model.HospitalSurgeryRequiredDocuments.filter(
    //         (x) => x.IsUploaded == false
    //       ).length == 0) ||
    //       this.Model.HospitalSurgeryRequiredDocuments == null ||
    //       this.Model.HospitalSurgeryRequiredDocuments == undefined ||
    //       this.Model.HospitalSurgeryRequiredDocuments.length == 0)

    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },

    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },

    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    PayNow() {
      // this.ShowConfirmation = false;
      // this.Showpayment = true;
      this.$vs.loading();
      this.surgeryModel.FrontUrl = window.location.origin;
      this.$store
        .dispatch("patientList/ProcessingPaymentDatail", this.surgeryModel)
        .then((res) => {
          debugger;
          window.location.href = res.data.Data.transaction.url;
          // this.$vs.loading.close();
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value = res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;

          // document.getElementById("paymentForm").submit();
          // var vm = this;
          // setTimeout(function () {
          //   vm.ShowConfirmation = false;
          // }, 1000);
          // debugger;
          this.ShowConfirmation = false;
        });
    },

    upload(id, doc) {
      debugger;
      this.Item = doc;
      var el = document.getElementById("uploadImgInput" + id);
      el.click();
    },

    backConfirmation() {
      this.ShowConfirmation = false;
      window.showAddSuccess();
    },

    EditHospitalSurgery(HospitalSurgeryID) {
      this.$router.push(
        "/hospital/Add-EditTreatmentSurgery/" + HospitalSurgeryID
      );
    },
    back() {
      this.$router.go(-1);
    },
    BookTheSurgery() {
      if (!this.$store.state.auth.isUserLoggedIn()) {
        this.ShowLoginPopup = true;

        return false;
      } else {
        this.ShowBookTheSurgery1 = true;
        // this.ShowConfirmation = false;
      }
    },
    AddAttachmnet() {
      debugger;
      this.documentModel.PatientMedicalHistoryID =
        this.patientModel.PatientMedicalHistory.ID;
      this.documentModel.PatientID = this.patientModel.ID;

      if (this.documentModel.DocumentTypeID == 1) {
        //scan type
        this.PatientMedicalHistoryScans.push(this.documentModel);
      } else if (this.documentModel.DocumentTypeID == 2) {
        //analysis type
        this.PatientMedicalHistoryAnalysis.push(this.documentModel);
      } else {
        //report type
        this.PatientMedicalHistoryRepors.push(this.documentModel);
      }
      this.Model.HospitalSurgeryRequiredDocuments.forEach((element) => {
        if (this.documentModel.DocumentTypeID == 1 && element.ScanTypeID > 0) {
          //scan
          element.Checked = true;
        } else if (
          this.documentModel.DocumentTypeID == 2 &&
          element.AnalysisTypeID > 0
        ) {
          element.Checked = true;
        } else if (
          this.documentModel.DocumentTypeID == 3 &&
          element.ReportTypeID > 0
        ) {
          element.Checked = true;
        }
      });
      this.documentModel = {};
      this.ShowDocumentUpload = false;
      this.ShowBookTheSurgery1 = true;
    },
    AddPatientMedicalReportRequest() {
      debugger;
      var PatientRequestModel = {};
      PatientRequestModel.PatientReservationSurgeryID =
        this.$route.params.PatientReservationSurgeryID;
      PatientRequestModel.DocumentID = this.addItemModel.DocumentID;
      PatientRequestModel.AnalysisTypeID = this.addItemModel.AnalysisTypeID;
      PatientRequestModel.ReportTypeID = this.addItemModel.ReportTypeID;
      PatientRequestModel.ScanTypeID = this.addItemModel.ScanTypeID;
      PatientRequestModel.Description = this.addItemModel.Description;
      this.addItemModel = {};
      PatientRequestModel.StausID = 1;
      this.$vs.loading();
      this.$store
        .dispatch(
          "patientList/AddPatientMedicalReportRequest",
          PatientRequestModel
        )
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
            window.showSuccess();
          }

          this.$vs.loading.close();
        });
      this.ShowAddSurgeryItemPopup = false;
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);
        this.documentModel = {};
        axios.post(this.uploadUrl, data).then((response) => {
          this.documentModel.AttachPath = response.data;
          this.successUpload(this.Item);
        });
      }
    },
    successUpload(item) {
      debugger;
      item.IsUploaded = true;
      item.AttachPath = this.documentModel.AttachPath;
      this.documentModel.PatientMedicalHistoryID =
        this.patientModel.PatientMedicalHistory.ID;
      this.documentModel.PatientID = this.patientModel.ID;
      this.documentModel.ReportTypeID = item.ReportTypeID;
      this.documentModel.AnalysisTypeID = item.AnalysisTypeID;
      this.documentModel.ScanTypeID = item.ScanTypeID;
      this.documentModel.Description =
        this.Model.Surgery.Name +
        "-" +
        item.DocumentTypeName +
        "-" +
        item.Description;
      this.documentModel.DocumentTypeID = item.DocumentTypeID;
      this.documentModel.HospitalSurgeryRequiredDocumentID = item.ID;
      if (this.documentModel.ScanTypeID > 0) {
        //scan type
        this.PatientMedicalHistoryScans.push(this.documentModel);
      } else if (this.documentModel.AnalysisTypeID > 0) {
        //analysis type
        this.PatientMedicalHistoryAnalysis.push(this.documentModel);
      } else {
        //report type
        this.PatientMedicalHistoryRepors.push(this.documentModel);
      }
    },
    RemoveDocuments(documentModel) {
      debugger;
      if (documentModel.ScanTypeID > 0) {
        //scan type
        const ItemIndex = this.PatientMedicalHistoryScans.findIndex(
          (p) => p.AttachPath == documentModel.AttachPath
        );
        this.PatientMedicalHistoryScans.splice(ItemIndex, 1);
      } else if (documentModel.AnalysisTypeID > 0) {
        //analysis type
        const ItemIndex = this.PatientMedicalHistoryAnalysis.findIndex(
          (p) => p.AttachPath == documentModel.AttachPath
        );
        this.PatientMedicalHistoryAnalysis.splice(ItemIndex, 1);
      } else {
        //report type
        const ItemIndex = this.PatientMedicalHistoryRepors.findIndex(
          (p) => p.AttachPath == documentModel.AttachPath
        );
        this.PatientMedicalHistoryRepors.splice(ItemIndex, 1);
      }
      documentModel.IsUploaded = false;
    },
    BackPopup1() {
      debugger;
      this.Model.DateFromFormated = null;
      this.Model.DateToFormated = null;
      this.Model.range = null;
      if (
        this.Model.HospitalSurgeryRequiredDocuments != null ||
        (this.Model.HospitalSurgeryRequiredDocuments != undefined &&
          this.Model.HospitalSurgeryRequiredDocuments.length > 0)
      ) {
        this.Model.HospitalSurgeryRequiredDocuments.forEach((element) => {
          element.IsUploaded = false;
          element.AttachPath = null;
        });
      }
      this.PatientMedicalHistoryScans = [];
      this.PatientMedicalHistoryAnalysis = [];
      this.PatientMedicalHistoryRepors = [];
      this.ShowBookTheSurgery1 = false;
    },
    BackProcessFeesPopup() {
      this.ShowConfirmation = false;
      this.$router.push({
        name: "BookingSummary",
        params: { Flag: 2, ID: this.surgeryModel.ID },
      });
    },
    Proceed() {
      this.surgeryModel.ID = 0;
      this.surgeryModel.PatientID = this.patientModel.ID;
      if (this.surgeryModel.PatientID == undefined) {
        this.surgeryModel.PatientID =
          this.$store.state.AppActiveUser.Patient.ID;
      }
      this.surgeryModel.HospitalSurgeryID = this.Model.ID;
      this.surgeryModel.ProcessingFees = this.Model.ProcessingFees;
      this.surgeryModel.VAT = this.Model.VAT;
      this.surgeryModel.PaymentStatusID = 1;
      this.surgeryModel.SurgeryDateFrom = this.Model.range.start;
      this.surgeryModel.SurgeryDateTo = this.Model.range.end;

      this.surgeryModel.StatusID = 1;
      this.surgeryModel.PatientMedicalHistoryScans =
        this.PatientMedicalHistoryScans;
      this.surgeryModel.PatientMedicalHistoryAnalysis =
        this.PatientMedicalHistoryAnalysis;
      this.surgeryModel.PatientMedicalHistoryRepors =
        this.PatientMedicalHistoryRepors;

      this.$vs.loading();

      this.$store
        .dispatch("patientList/AddPatientReservationSurgery", this.surgeryModel)
        .then((res) => {
          debugger;
          this.surgeryModel = res.data.Data;
          window.showSuccess(res.data.message);
          this.$vs.loading.close();
          this.ShowBookTheSurgery1 = false;
          this.ShowConfirmation = true;
          this.GetSurgery();
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
        });
    },

    Confirm() {
      this.ShowConfirmation = false;
      this.Showpayment = true;
    },

    ConfirmPayment() {
      this.Showpayment = false;
      debugger;
      if (
        this.PatientMedicalHistoryScans.length > 0 ||
        this.PatientMedicalHistoryAnalysis.length > 0 ||
        this.PatientMedicalHistoryRepors.length > 0
      ) {
        this.PatientMedicalHistoryScans = [];
        this.PatientMedicalHistoryAnalysis = [];
        this.PatientMedicalHistoryRepors = [];
        //  this.ShowBookingSurgeryConfirm = true;
        debugger;

        this.$router.push({
          name: "BookingSummary",
          params: { Flag: 2, ID: this.surgeryModel.ID },
        });
      } else {
        window.noDocumentsUploaded();
      }
    },
    showDownloadReports() {
      debugger;
      this.patientDoc.PatientReservationSurgeryID =
        this.$route.params.PatientReservationSurgeryID;

      this.showDownloadReportsfield = true;
      debugger;
    },
    payLater() {
      this.Showpayment = false;
      window.showSuccess();
    },
    getPatientReservationSurgery(id) {
      var model = {};
      model.PatientId = id;
      model.StatusID = 2; //confirmed
      this.$vs.loading();
      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    changeDateFormat() {
      debugger;
      this.Model.DateFromFormated = moment(this.Model.range.start).format("LL");
      this.Model.DateToFormated = moment(this.Model.range.end).format("LL");
    },
    addDays(days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    GetSurgery() {
      const ID = this.$route.params.ID;
      this.$vs.loading();
      debugger;
      this.$store
        .dispatch("patientList/GetHospitalSurgery", ID)
        .then((res) => {
          debugger;
          if (res.status == 200) {
            debugger;
            this.Model = res.data.Data;
            this.Model.StatusID;
            debugger;
            if (
              this.Model.Hospital.Rate == null ||
              this.Model.Hospital.Rate == undefined ||
              this.Model.Hospital.Rate == "NaN"
            ) {
              this.Model.Hospital.Rate = 0;
            }

            if (
              this.$acl.check("patient") &&
              this.$route.params.ViewTypeID != undefined
            ) {
              this.surgeryModel.ID =
                this.$route.params.PatientReservationSurgeryID;

              if (this.$route.params.ViewTypeID == 14) {
                this.surgeryModel = {};

                this.$router.push({
                  name: "BookingSummary",
                  params: {
                    Flag: 2,
                    ID: this.$route.params.PatientReservationSurgeryID,
                  },
                });
              } else this.Showpayment = true;
            }
          }

          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    debugger;
    this.IsDoctorRole = this.$acl.check("doctor");
    debugger;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");

    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    debugger;
    if (
      (this.$acl.check("hospital") || this.$acl.check("hospitalgroup")) &&
      (this.$route.params.ViewTypeID == undefined ||
        this.$route.params.ViewTypeID == 0)
    ) {
      this.showAddRequest = true;
    }
    if (
      this.$acl.check("doctor") &&
      (this.$route.params.ViewTypeID == undefined ||
        this.$route.params.ViewTypeID == 0)
    ) {
      debugger;
      this.showAddRequest = true;
    }
    debugger;
    this.GetSurgery();

    if (
      !this.$acl.check("hospital") &&
      !this.$acl.check("hospitalgroup") &&
      !this.$acl.check("doctor")
    ) {
      this.$store
        .dispatch(
          "patientList/GetPatient",
          this.$store.state.AppActiveUser.Patient.ID
        )
        .then((res) => {
          this.patientModel = res.data.Data;
          if (
            this.patientModel.PatientMedicalHistory == null ||
            this.patientModel.PatientMedicalHistory == undefined
          ) {
            this.patientModel.PatientMedicalHistory = {};
          }
        });
    }
  },
};
</script>
<style>
#doctors .vx-row {
  margin: unset;
}
.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons :lang(en) {
  font-family: unset;
}
.FontHead {
  font: normal normal medium 40px/51px Futura PT;
  letter-spacing: 0px;
  color: #454a62;
}
.colorHead {
  color: #0d66d0;
  /* font: normal normal medium 54px/69px Futura PT; */
  font-family: UbuntuRegular;
  font-weight: bold;
  font-size: 1.6rem;
}

.HospitalTitle {
  font-family: UbuntuRegular;
  font-weight: bold;
  font-size: 1.1rem;
}

.HospitalInfo {
  font-family: UbuntuRegular;
  font-size: 0.95rem;
}

.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.popBackGround {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
.popcard .vx-card {
  box-shadow: unset;
}
.CustBG {
  background:
    radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
.CurrentPageStatment {
  top: 414px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 1;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
}
/*  */
.OtherPageStatment {
  top: 532px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 0.3;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
  /* font-size: 2.5vh; */
}
.custHeadSurgery {
  font-weight: bold;
  font-size: 1.7rem;
  color: #004477;
}
.sizeCust {
  font-size: 1rem;
}

#treatP .vx-card {
  padding: 0px !important;
}
#treatP .vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 0 !important;
}

.docCardRow > .docCardCol {
  padding: 0;
}
.sr-only[data-v-fde73a0c] {
  left: 0px !important;
}
.sr-only {
  left: 0px !important;
}
</style>
